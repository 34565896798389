<template>
  <div class="newsContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">通知详情</div>
    <div class="card">
        <div class="newtitle">{{data.noticeTheme}}</div>

        <div class="card-html" v-html="data.noticeContent"></div>

    </div>

    
    <!-- <div style="height: 15vw; width:100%"></div> -->
    <!-- <TabBar nav="news"/> -->
  </div>
</template>
<script>
import { apiIndexData } from '../../api/axios';
import { Toast } from 'vant';
import { ref } from 'vue';
export default {
   setup() {
     const data = ref({});
     apiIndexData({}).then(res => {                  
             if(res.code == '1'){
                 data.value = res.info.latestNotice; 
              }
              else {
                Toast(res.msg);
              }
          }); 
    return { 
      data
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  },
  // components: {
  //   TabBar
  // }
}
</script>
<style lang="less" scoped>
.newsContent {
    padding-top: 12.5vw;
    .card {
        .newtitle {
            font-size: 5vw;
            font-weight: 500;
        }
        .newtime {
            font-size: 3.3vw;
            color: #ccc;
            padding: 4vw 0;
            img {
            width: 3.5vw;
            height: auto;
            margin-right: 1vw;
            }
        }
        .newtext {
            font-size: 4vw;
            margin: 4vw 0;
            line-height: 7vw;
        }
        img {
            width: 100%;
            height: auto;
        }
        .accessory {
          font-size: 4vw;
          span {
            color: rgb(19, 100, 250);
          }
        }
    }
}
</style>
